











































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';
import { clearSession } from '@/lib/auth';
import { getComponent } from '@/utils/helpers';
import { SHOW_ALERT } from '@/lib/constants';
import workflowModule from '@/store/modules/workflowModule';
import {
  preloadData,
} from '@/utils/preloadOfflineData';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Component({
  components: {
    AppSidebar: () => getComponent('common/AppSidebar'),
    AppFooter: () => getComponent('common/AppFooter'),
  },
})
export default class AppLayout extends Vue {
  isSidebarClosed = false;

  notificationsOpen = false;

  open = false;

  right = false;

  settingsOpen = false;

  showModal = false;

  showAlert = false;

  alertText = '';

  userSettingsPageLink = {
    name: 'UserSettings',
    to: 'UserSettings',
  }

  selectedBoardingItem: any = null;

  onBoardingItems = [
    {
      title: 'Getting started',
      subTitle: 'Explore key features and possibilities.',
      icon: 'onboarding.png',
      type: 'GettingStarted',
      external: true,
      src: 'https://tasq.io/updates',
    },
    {
      title: "What's New",
      subTitle: 'Find out latest product changes.',
      icon: 'whatsnew.png',
      type: 'New',
      external: true,
      src: 'https://tasq.io/updates',
    },
    {
      title: 'Get Help',
      subTitle: "Need assistance? We're here to help.",
      icon: 'gethelp.png',
      type: 'Help',
      external: true,
      src: 'https://tasq.io/updates',
    },
  ]

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async beforeMount() {
    await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase() });
    await workflowModule.getChartDrawingDetails({nodeID:"GORDON STANSBERRY 5H"})
    // console.log(workflowModule.user);
    if (window.location.pathname === '/') {
      if (workflowModule.user.defaultPage && !workflowModule.user.defaultPage.includes('Tasq')) {
        this.$router.push({ name: workflowModule.user.defaultPage });
      } else if (workflowModule.user.defaultPage && workflowModule.user.defaultPage.includes('Tasq')) {
        if (workflowModule.user.defaultPage && workflowModule.user.defaultPage === 'TasqList-Well') {
          this.$router.push({ name: 'Tasqs' });
          tasqsListModule.setTasqListLevel('Well');
          // console.log(tasqsListModule.tasqListLevel);
        } else if (workflowModule.user.defaultPage && workflowModule.user.defaultPage === 'TasqList-Pad') {
          this.$router.push({ name: 'Tasqs' });
          tasqsListModule.setTasqListLevel('Pad');
        }
      
      } else {
         this.$router.push({ name: 'Tasqs' });
      }
    }
  }

  async created() {
    this.$eventBus.$on(SHOW_ALERT, (text) => {
      this.showAlert = true;
      this.alertText = text;
      this.timeAlert(4000);
    });

    if (navigator.onLine) {
      if (!accountModule.didPreloadData) {
        console.log('preloading');
			 await preloadData();
      }
    }
  }

  showBoardingModal(item) {
    this.selectedBoardingItem = item;
    this.showModal = true;
  }

  closeFilterDialog() {
    this.settingsOpen = false;
  }

  toggle() {
    this.open = !this.open;
  }

  async timeAlert(time) {
	  await this.sleep(time);
    this.showAlert = false;
    this.alertText = '';
  }

  handleSidebarItemClick(link) {
    this.$router.push({
      name: link.to,
    });
  }

  logout() {
    clearSession();
    this.$router.push({ name: 'Login' });
  }

  onSideBarClose() {
    this.isSidebarClosed = true;
  }

  toggleSidebar() {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    this.$refs.sidebar.toggleSidebarMenu();
    this.isSidebarClosed = false;
  }
}
